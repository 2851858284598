<template>
  <div class="address-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>特色服务</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="row">
        <div
          class="card box-half"
          v-for="item in activeProjectList"
          :key="item.PK_ID"
          @click="
            $router.push({ name: 'BizDetail', query: { id: item.PK_ID } })
          "
        >
          <img
            :src="
              item.sImageUrl.startsWith('http')
                ? item.sImageUrl
                : baseUrl + item.sImageUrl
            "
          />
          <div class="title">{{ item.sProjectTitle }}</div>
          <div class="desc">
            {{ item.sDesc }}
          </div>
          <div class="x-center">
            <van-button type="info" size="mini">查看详情</van-button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { BASE_URL } from '@/api/index.js';
import { getProjectList } from '@/api/website.js';
//import { formatDate } from '@/utils/index.js';
// function getValidTime(value) {
//   if (value == null || value == '') return '';
//   var myDate = new Date(value.match(/\d+/)[0] * 1);
//   return formatDate(myDate);
// }
export default {
  data() {
    return {
      active: 0,
      activeProjectList: [],
      baseUrl: BASE_URL,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      getProjectList(100).then(res => {
        if (res.Result == true) {
          console.log(res);
          this.activeProjectList = res.DataDict['Data'].data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(20px);
}
.card {
  margin: rem(15px);
  border-radius: rem(10px);
  background-color: #fff;
  padding: rem(20px);
  img {
    width: 95%;
    height: rem(159px);
    text-align: center;
  }
}
.box-half {
  width: 40%;
  float: left;
  position: relative;
}
.title {
  margin-top: rem(20px);
  margin-bottom: rem(24px);
  color: #666;
}
.desc {
  font-size: 12px;
  color: #b8b8b8;
  margin-bottom: rem(10px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-button {
  padding-left: rem(40px);
  padding-right: rem(40px);
}
</style>
